import React from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import { PageMeta } from '@components/pageMeta';
import BlockZone from '~blockZone';
import Layout from '@components/layout';

const IndexPage = ({ data: { page } }) => {
  const { title, meta, pageBlocks, settings } = page || {};

  return (
    <Layout altLogo={true} settings={settings}>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
      settings {
        revereseText
        reverseHeader
        searchExcerpt
        bgColor {
          hex
        }
      }
    }
  }
`;
